@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.youtube-video {
    &-picture-wrapper {
        /* stylelint-disable-next-line */
        &::before {
            background-color: #000;
        }
    }

    &-title {
        color: var(--text-primary);
        font-family: var(--font-primary);
        font-size: var(--text-lg);
        font-weight: bold;
        line-height: 26px;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            font-size: var(--text-2xl);
            line-height: 45px;
        }
    }

    &-description {
        color: var(--text-primary);
        font-family: var(--font-primary);
        font-size: var(--text-xs);
        line-height: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            font-size: var(--text-lg);
            line-height: 25px;
        }

        @include media-breakpoint-up(lg) {
            font-size: var(--text-sm);
            line-height: 20px;
        }

        @include media-breakpoint-up(xl) {
            font-size: var(--text-base);
            line-height: 23px;
        }
    }
}
