@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./youtubeVideoTile__typography";

.youtube-video {
    position: relative;

    &.margins-enabled {
        margin-top: 15px;

        @include media-breakpoint-up(md) {
            margin-top: 32px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 21px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 31px;
        }
    }

    img,
    picture {
        display: block;
        width: 100%;
    }

    &-player {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-picture-wrapper {
        align-items: center;
        cursor: pointer;
        justify-content: center;
        position: relative;
        /* stylelint-disable-next-line */
        &::before {
            content: "";
            display: block;
            height: 100%;
            opacity: 0.5;
            position: absolute;
            width: 100%;
            z-index: 1;
        }
        /* stylelint-disable-next-line */
        .player-initialized & {
            visibility: hidden;
        }
        /* stylelint-disable-next-line */
        &:hover {
            /* stylelint-disable-next-line */
            &-play-btn {
                transform: scale(1.1);
            }
        }
    }

    &-content-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 50%;
        justify-content: center;
        width: 50%;
        z-index: 100;
    }

    &-title {
        overflow: hidden;
    }

    &-description {
        overflow: hidden;

        @include media-breakpoint-up(md) {
            margin-top: 19px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-top: 10px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 11px;
        }
    }

    &-play-btn {
        display: block;
        left: calc(50% - 25px);
        top: calc(50% - -55px);
        transition: transform 0.5s;
        z-index: 10;

        @include media-breakpoint-up(lg) {
            top: calc(50% - -80px);
        }
    }
}
